<template>
  <div class="page home" v-if="userData">
    <h1>{{ userData.voornaam }} {{ userData.familienaam }}</h1>
    <h2><span>{{$t('home.licence')}}:</span> {{userData.bestuurderspas}}</h2>
    <div class="page__content">
        <p><b>{{$t('status')}}</b></p>
        <div class="home__container" v-if="userData">
          <div class="home__block home__block--tasks" @click="$router.push('/schedule')">
            <div class="home__block__status">{{ ritten.length }}</div>
            <p class="home__block__text">{{$t('home.todo')}}</p>
          </div>
          <div class="home__block home__block--account" @click="$router.push('/settings')">
            <template v-if="vervalError">
              <span class="home__block__status material-icons error">error</span>
              <p class="home__block__text">
                {{vervalError==='vervallen'?$t('home.medic_expired'):vervalError==='onbekend'?$t('home.medic_missing'):$t('home.medic_expiring')}} <b>{{ userData.vervaldagmedischattest }}</b></p>
            </template>
            <template v-else>
              <span class="home__block__status material-icons success">checkmark</span>
              <p class="home__block__text">{{$t('home.medic_OK')}}</p>
            </template>
          </div>
        </div>
        <p><b>{{ $t('assignments.title') }}</b></p>
        <ConfirmList />
        <p><b>{{$t('home.administration')}}</b></p>
        <button class="button" @click="openService">{{$t('documents.service')}}</button>
        <button class="button" @click="contractsVisible=true">{{$t('contracts.title')}}</button>
        <router-link class="button" to="Rating"><span>{{$t('rating.title')}}</span><Stars :percentage="userData.stars" maskColor="#1963D1"/></router-link>
        <router-link class="button" to="TimeTable">{{$t('timetable.timetable')}}</router-link>
        <router-link class="button" to="Leave">{{$t('leave.title')}}</router-link>
        <router-link class="button" to="Contracts">{{$t('mycontracts.title')}}</router-link>
        <a class="button" target="_blank" :href="`https://www.billo.be/partners/aanvraagherstelling.php?LINKCHAUFFEUR=${userData.pers_id}`">{{$t('home.maintenance')}}</a>
        <button class="button" @click="linksVisible=true">{{$t('links.title')}}</button>
      </div>
      <teleport to="#app">
      <transition name="fade">
        <ContractList v-if="contractsVisible" @on-close="contractsVisible=false"/>
      </transition>
      <transition name="fade">
        <LinksList v-if="linksVisible" @on-close="linksVisible=false"/>
      </transition>
      </teleport>
  </div>
</template>

<script>

import ConfirmList from "@/components/ConfirmList";
import ContractList from "../components/ContractList.vue";
import LinksList from "../components/LinksList.vue";
import Stars from "../components/Stars.vue";
export default {
  name: 'Home',
  components: {
    ConfirmList,
    ContractList,
    LinksList,
    Stars
},
  data(){
    return {
      CHECK_TIME: 5 * 60 * 1000,
      checkTimeout: null,
      contractsVisible: false,
      linksVisible: false
    }
  },
  created() {
    //document.addEventListener('visibilitychange', this.visibilityChanged);
    this.checkData();
  },
  beforeUnmount() {
    //document.removeEventListener('visibilitychange', this.visibilityChanged);
    clearTimeout(this.checkTimeout);
  },
  computed: {
    ritten() {
      return this.$store.getters['ritten/rittenToDo'];
    },
    userData() {
      return this.$store.getters['user/userData'];
    },
    vervalError() {
      if (this.userData && this.userData.vervaldagmedischattest.trim()) {
        const date = this.userData.vervaldagmedischattest;
        if(!date){
          return 'onbekend';
        }
        const vDate = new Date(date);
        const nDate = new Date();
        vDate.setHours(0, 0, 0);
        nDate.setHours(0, 0, 0);
        const diffTime = vDate - nDate;
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        return diffDays < 60?diffDays<=0?'vervallen':'geldig':false;
      }
      else if(this.userData){
        return 'onbekend';
      }
      else {
        return false;
      }
    }
  },
  methods: {
    checkData(){
      clearTimeout(this.checkTimeout);
      this.$store.dispatch('ritten/getConfirmRitten');
      this.$store.dispatch('ritten/getRitten');
      this.checkTimeout = setTimeout(()=> {
        this.checkData();
      }, this.CHECK_TIME);
    },
    async openService(){
      try{
        const data = await this.$store.dispatch('user/getServiceRecord');
        if (!data.filename) {
          alert("File not found.");
          return;
        }
        const link = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/${data.filename}`;
        window.open(link, "_blank", "fullscreen=yes");
      }
      catch(e){
        alert(e);
      }
    }
  }
}
</script>
<style lang="scss" scoped>
  @import '../assets/scss/views/home';
  a span{
    vertical-align: middle;
    margin-right: 0.5rem;
  }
  a > div{
    display: inline-block;
  }
</style>
