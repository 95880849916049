import apihelper from "../../../helpers/apihelper";

export default {
  async getUserData(context) {
    const token = context.rootGetters.token;
    const username = context.rootGetters.userName;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/users/registrationdata/${username}`;
    const responseData = await apihelper.fetchGetRequest({
      url,
      token,
      errorMsg: "Failed to fetch user data or access denied.",
    });
    context.commit("setUserData", {
      userData: responseData.data,
    });
  },

  clearUserData(context) {
    context.commit("setUserData", {
      userData: null,
    });
  },

  async saveUserProfile(context, formData) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/users/saveregistrationdata`;
    const res = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    });
    const responseData = await res.json();
    if (!res.ok || responseData.error) {
      const error = new Error(
        responseData.error_description ||
          "Opslaan van gegevens is foutgelopen, probeer opnieuw."
      );
      throw error;
    }
    await context.dispatch("getUserData");
  },

  async getServiceRecord(context) {
    const token = context.rootGetters.token;
    const userId = context.getters.userData.pers_id;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/preview.json/${userId}?template=controlerapport`;
    const responseData = await apihelper.fetchGetRequest({
      url,
      token,
      errorMsg: "Failed to fetch user service record.",
    });
    return responseData.data;
  },

  async getRating(context) {
    const token = context.rootGetters.token;
    const userId = context.getters.userData.pers_id;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/users/selectstars/${userId}`;
    const responseData = await apihelper.fetchGetRequest({
      url,
      token,
      errorMsg: "Failed to fetch user rating.",
    });
    return responseData.data;
  },
  async getFeedback(context) {
    const token = context.rootGetters.token;
    const userId = context.getters.userData.pers_id;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/users/selectfeedback/${userId}`;
    const responseData = await apihelper.fetchGetRequest({
      url,
      token,
      errorMsg: "Failed to fetch user feedback.",
    });
    return responseData.data;
  },
  async getLinks(context) {
    const token = context.rootGetters.token;
    const userLang = context.getters.userData.taal;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/users/leeslinks?taal=${userLang}`;
    const responseData = await apihelper.fetchGetRequest({
      url,
      token,
      errorMsg: "Failed to fetch user links.",
    });
    return responseData.data;
  },

  async getContracts(context){
    const token = context.rootGetters.token;
    const userId = context.getters.userData.pers_id;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/users/contracten/${userId}`;
    const responseData = await apihelper.fetchGetRequest({
      url,
      token,
      errorMsg: "Failed to fetch user contracts.",
    });
    return responseData.data;
  },
  async getTimeData(context) {
    const token = context.rootGetters.token;
    const userId = context.getters.userData.pers_id;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/users/uurrooster/${userId}`;
    const responseData = await apihelper.fetchGetRequest({
      url,
      token,
      errorMsg: "Failed to fetch user timetable.",
    });
    return responseData.data;
  },
  async getTimeHistoryData(context) {
    const token = context.rootGetters.token;
    const userId = context.getters.userData.pers_id;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/users/definitiefrooster/${userId}`;
    const responseData = await apihelper.fetchGetRequest({
      url,
      token,
      errorMsg: "Failed to fetch user completed timetable.",
    });
    return responseData.data;
  },
  async getLeaveData(context) {
    const token = context.rootGetters.token;
    const userId = context.getters.userData.pers_id;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/users/verlofaanvragen/${userId}`;
    const responseData = await apihelper.fetchGetRequest({
      url,
      token,
      errorMsg: "Failed to fetch user leave requests.",
    });
    return responseData.data;
  },
  async getLeaveHistory(context){
    const token = context.rootGetters.token;
    const userId = context.getters.userData.pers_id;
    const date = new Date();
    const year = date.getFullYear();
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/users/opgenomenverlof/${userId}?jaar=${year}`;
    const responseData = await apihelper.fetchGetRequest({
      url,
      token,
      errorMsg: "Failed to fetch user leave history.",
    });
    return responseData.data;
  },
  async addLeave(context, payload) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/users/addVerlofAanvraag`;
    await apihelper.fetchPostRequest({
      url,
      token,
      payload: JSON.stringify(payload),
      errorMsg: "Failed to submit leave request.",
    });
  },
};
